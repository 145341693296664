import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";
import { useIsaretTazele } from "./isaretTazeleKullan";
import { useIstemciSoketiKullan } from "./istemciSoketiKullan";
import { removeUserFromVideoRoom } from '../VideoOdasi/videoOdasiDilimi'

export const useCikis = () => {
    const { t } = useTranslation();
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte } = useGenelAyarlarBaglami()
    const {isaretTazele} = useIsaretTazele()
    const yonlendir = useNavigate()
    const { soketSunucusundanAyril } = useIstemciSoketiKullan()
    const dispatchforVideoDilimi = useDispatch()

    const cikisYap = async () => {
        try {
            setYukluyor(true)

            console.log("çıkışta kabulEdilenDil: ", kabulEdilenDil)
            setBeklemekte({beklemeMesaji: t("flashMessages:CikisYapiyorFS"),
                beklesin: true})
            const yanit = await fetch(`/signoutapi`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + kullanici.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: ""
            })
            // console.log("çıkış yanit: ", yanit.json())
            // console.log("çıkış yanit.data: ", yanit.data)
            if (!yanit.ok) {
                const cikisVerisi = await yanit?.json()
                console.log("if (!yanit.ok) {")
                if(yanit.status === 401){
                    if(cikisVerisi?.sonuc === "Caylari Tazele"){
                        await isaretTazele()
                    }
                    dispatch({type: 'SIGNOUT', payload: null})
                    soketSunucusundanAyril()
                }
                setYukluyor(true)
                setBeklemekte({beklemeMesaji: t("flashMessages:CikisYapiyorFS"),
                    beklesin: false})
            }
            else { //if (!yanit.ok) { }
                console.log("cıkışta yanit: ", yanit)
                localStorage.removeItem('kullanici')
                dispatch({type: 'SIGNOUT', payload: null})
                soketSunucusundanAyril()
                dispatchforVideoDilimi(removeUserFromVideoRoom(kullanici))
                toast
                .success(t("flashMessages:SuccessFullySignedOutFS"))
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:CikisYapiyorFS"),
                    beklesin: false})
                    yonlendir("/")
            } // else // if (yanit.ok) {
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:CikisYapiyorFS"),
                beklesin: false})
        }
    }

    return { cikisYap, yukluyor }
}