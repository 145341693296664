import React from 'react'
import { Link } from 'react-router-dom'
import YonlendirenBilesen from '../Bilesenler/YonlendirenBilesen'
import { useTranslation } from 'react-i18next'

function NotFound() {
    const { t } = useTranslation()
    return (
        <section className='centerCls'>
        {/* <YonlendirenBilesen/> */}
        <h1>{t('genericStrings:errorOccurredS')}</h1>
        <Link to={"/"}>Home Page</Link>
        </section>
    )
}

export default NotFound