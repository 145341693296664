import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";
import { useLocation } from "react-router-dom";

export const useIsaretTazele = () => {
    const { t } = useTranslation();
    const [ yukluyorIsaret, setYukluyorIsaret] = useState(false)
    const [ yonlendirilecekURLIsaret, setYonlendirilecekURLIsaret] = useState('')
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte } = useGenelAyarlarBaglami()
    const gecerliURL = useLocation()

    const isaretTazele = async () => {
        try {
            let gonderilecekVeri = {}
            gonderilecekVeri["birSonrakiYonlendirilecekURL"] = gecerliURL.pathname
            setYukluyorIsaret(true)
            console.log("gecerliURL: ", gecerliURL)
            setBeklemekte({beklemeMesaji: "",
                beklesin: true})
            const yanit = await fetch(`/isarettazele`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + kullanici.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(gonderilecekVeri)
                // body: ""
            })
            // console.log("profili getir ulan")
            // console.log("tazele yanit: ", yanit)
            // const cekilenVeri = await yanit.json()
            if (!yanit.ok) {
                if(yanit.status === 401){
                }
                else if(yanit.status === 500){
                    toast.remove("isaretTazeleKullan1")
                    toast.error(t("flashMessages:server500ErrorFS"), {id: "isaretTazeleKullan1"})
                } 
                setYukluyorIsaret(false)
                setBeklemekte({beklemeMesaji: "",
                    beklesin: false})
            }
            else { //(!yanit.ok) {
                // console.log("profilGetir cekilenVeri.profilVerileri: ")
                const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
                let localStorageKullanici = JSON.parse(localStorage.getItem('kullanici'))
                localStorageKullanici.isaret = isaret
                localStorage.setItem("kullanici", JSON.stringify(localStorageKullanici)) //bunu yapmazsan bazen kaydetmiyor
                dispatch({type: 'PROFILE_UPDATED', payload: localStorageKullanici})
                console.log("İşaret Tazeledeki Mantık Doğru Mu?")
                setBeklemekte({beklemeMesaji: "",
                    beklesin: false})
            }
        } catch (err) {
            console.log(err)
            setYukluyorIsaret(false)
            setBeklemekte({beklemeMesaji: "",
                beklesin: false})
        }
    }

    return { isaretTazele, yukluyorIsaret, yonlendirilecekURLIsaret }
}