import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CookiesProvider } from "react-cookie";

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from "react-router-dom"

import './i18n'

import './Stylelar/main.css'
import { GenelAyarlarSaglayici } from "./baglam/GenelAyarlarSaglayici";
import { KimlikDogrulamaSaglayici } from "./baglam/KimlikDogrulamaSaglayici";
import Signin from "./Bilesenler/SignIn";
import SignUp from "./Bilesenler/SignUp";
import NotFound from "./Pages/NotFound";
import VideoOdasiAna from "./Pages/VideoOdasiAna"
import KokYerlesim from "./yerlesimler/KokYerlesim";
import AccordionComp from "./Bilesenler/AccordionComp";
import i18n from "./i18n";
import { initializeTagManager } from "./gtm";
import { EpostaDogrula } from "./Bilesenler/EpostaDogrula";
import Hesabim from "./Bilesenler/Hesabim";
import SifreSifirla from "./Bilesenler/SifreSifirla";
import SifreSifirlamaTalebi from "./Bilesenler/SifreSifirlamaTalebi";
import Korunmayan from "./yerlesimler/Korunmayan";
import Korunan from "./yerlesimler/Korunan";
import VideoVeChatSayfasi from "./Pages/VideoVeChatSayfasi";

export const lang = i18n.language
console.log("lang: ", lang)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={"/"} exact element={<KokYerlesim/>}> {/* bu korunmuyor */}

      {/* Daima açık yollar */}
      <Route path="sifresifirla" element={<SifreSifirla />} />
      <Route path="sifresifirla/:emailtoVerify" element={<SifreSifirla />} />
      <Route path="sifresifirla/:emailtoVerify/:kurtarmakodu" element={<SifreSifirla />} />
      <Route path="emaildogrulama/" element={<EpostaDogrula />} />
      <Route path="sifresifirlamatalepet" element={<SifreSifirlamaTalebi />} />

      {/* Korunmayan yollar */}
      <Route element={<Korunmayan />}>
        <Route index element={<AccordionComp />} />
        <Route path="signin" element={<Signin />} />
        <Route path="signin/:restOfUrl" element={<Signin />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="signup/:restOfUrl/" element={<SignUp />} />
        <Route path="signup/:restOfUrl/:yer" element={<SignUp />} />
      </Route>

      {/* Korunan yollar */}
      <Route element={<Korunan />}>
        <Route path="videoodasi" element={<VideoOdasiAna />} exact />
        <Route path="videoodasi/:uuid" element={<VideoVeChatSayfasi />} />
        <Route path="videoodasi/*" element={<VideoOdasiAna />} />
        <Route path="hesabim" element={<Hesabim />} />
      </Route>
      
      {/* Bulunamayan yollar */}
      <Route path="*" element={<NotFound />} />
    </Route>
  ),
  {
    // basename: "/:"+lang+"/"
  }
)

const App = () => {
  const { t, ready } = useTranslation();
  const [documentTitle, setDocumentTitle] = useState(`Çeviri Hizmetleri Merkezi - ${t('genericStrings:web_site_name')}`)
  // const favIcon = 'https://stackoverflow.com/favicon.ico'
  // const favIcon = '/img/tercumeederizfavicon.ico'
  const favIcon = `/img/${t('genericStrings:iconToLoadS')}`

  useEffect( () => {
    initializeTagManager()
    setDocumentTitle((tk) => tk = t('genericStrings:descriptivePageTitleS') )
  }, [t, ready])

  useEffect( () => {
    document.title = documentTitle;
    const favIconLink = document.querySelector('link[rel="icon"]');
    favIconLink.setAttribute("href", favIcon)
    // console.log("favIcon: ", favIcon, " favIconLink: ", favIconLink)
  }, [documentTitle])

  // console.log('navigator.userActivation: ', navigator.userAgent)

  !ready && console.log('loading translations <Suspense> kullanmasını öğren')
  
  return (
    <CookiesProvider defaultSetOptions={{path:'/'}}>
    <KimlikDogrulamaSaglayici>
        <GenelAyarlarSaglayici>
          {/* <IstemciSoketSaglamaBaglami> */}
            {/* <div> */}
            <RouterProvider router={router} />
            {/* </div> */}
          {/* </IstemciSoketSaglamaBaglami> */}
        </GenelAyarlarSaglayici>
    </KimlikDogrulamaSaglayici>
    </CookiesProvider>
  );
};

// cd istemci npm start

export default App;
