import { configureStore } from '@reduxjs/toolkit'

import videoOdasiDilimiReducer from '../VideoOdasi/videoOdasiDilimi';
import videoOdasiSohbetleriDilimiReducer from '../VideoOdasi/videoOdasiSohbetleriDilimi';

const depo = configureStore({
    reducer: {
        videoOdasi: videoOdasiDilimiReducer,
        videoOdasiSohbetleriDilimi: videoOdasiSohbetleriDilimiReducer,
    }
});

export default depo;
