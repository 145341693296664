import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useGiris = () => {
    const { t } = useTranslation();
    const [ sifreSifirla, setSifreSifirla] = useState(false)
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch } = useKimlikDogrulamaBaglami()
    const { 
        kabulEdilenDil, setBeklemekte,
        genelAyarlarSimdikiAn, 
        setGenelAyarlarSimdikiAn,
        genelAyarlarGuncelemeTarihi, 
        setGenelAyarlarGuncelemeTarihi,
    } = useGenelAyarlarBaglami()
    const yonlendir = useNavigate()

    const girisYap = async (data) => {
        try {
            setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:GirisYapiyorFS"),
                beklesin: true})
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            const yanit = await fetch(`/signinapi`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            const veri = await yanit.json()
            if (!yanit.ok) {
                console.log("veri: ", veri)
                if(yanit.status === 401){
                    if(veri?.sonuc === "Kayda Yonlendir"){
                        // return <Navigate to={`/signup/${veri.email}`}/>
                        toast.remove("girisKullan1")
                        toast.error(t("flashMessages:UserCannotBeFoundFS"), {id: "girisKullan1"})
                        yonlendir(`/signup/${veri.email}`)
                    }
                    if(veri?.sonuc === "Sifre Sifirla"){
                        localStorage.removeItem('kullanici')
                        toast.remove("girisKullan2")
                        toast.error(t("flashMessages:YourPassWordMustBeResetFS"), {id: "girisKullan2"})
                        dispatch({type: 'SIGNOUT', payload: null})
                        // setSifreSifirla((ss) => true)
                        console.log("Burada sadece bildirimde bulun. Yönlendirme de yap")
                    }
                }
                else if(yanit.status === 403){
                    toast.remove("girisKullan3")
                    toast.error(t("flashMessages:UserOrPasswordIsWrongFS"), {id: "girisKullan3"})
                    setSifreSifirla((ss) => true)
                }
                else if(yanit.status === 500){
                    toast.remove("girisKullan4")
                    toast.error(t("flashMessages:server500ErrorFS"), {id: "girisKullan4"})
                }
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:GirisYapiyorFS"),
                    beklesin: false})
            }
            const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
            // console.log("login isaret: ", isaret)
            let sonVeri = {}
            // delete veri['sonuc']
            // let tmpVeri = veri.message
            sonVeri["message"] = veri.message
            sonVeri["username"] = veri.username
            sonVeri["email"] = veri.email
            sonVeri["kullanici_slugi"] = veri.kullanici_slugi
            sonVeri["toBeRedirectedTo"] = veri.toBeRedirectedTo
            sonVeri["messageWithRedirectionTo"] = veri.messageWithRedirectionTo
            sonVeri["profileUpdatedOn"] = veri.profileUpdatedOn
            // sonVeri["profilDuzenlemesiniErtele"] = veri.profilDuzenlemesiniErtele
            // sonVeri["ertelemeSuresi"] = veri.ertelemeSuresi
            sonVeri["isaret"] = isaret
            // sonVeri = {...sonVeri, isaret }
            if (veri?.sonuc === "Giris Basarili"){
                // bağlama ekle
                dispatch({type: 'SIGNIN', payload: sonVeri})
                toast
                .success(t("flashMessages:SuccessFullyLoggedInFS"))
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:GirisYapiyorFS"),
                    beklesin: false})
                setGenelAyarlarGuncelemeTarihi((gt) => gt = +new Date(veri.profileUpdatedOn))
                if(veri.toBeRedirectedTo !== ''){
                    yonlendir('/hesabim')
                    console.log("girisKullan if(veri.toBeRedirectedTo !== ''){")
                } // if(veri.toBeRedirectedTo !== ''){
                else {
                    yonlendir("/")
                } // else if(veri.toBeRedirectedTo !== ''){
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:GirisYapiyorFS"),
                beklesin: false})
            yonlendir("/")
        }
    }

    return { girisYap, yukluyor, sifreSifirla }
}