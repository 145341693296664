import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiSend } from "react-icons/bi";

import './SohbetKutusu.css'

const SohbetKutusu = () => {
    const { t } = useTranslation()

    return (
        <div className='sohbetKutusuKapsayiciCls formControlGridCls'>
            <label htmlFor='id'>{t('forms:userIdFormFieldS')}</label>
            <input 
                type='text'
                name='id'
                id='id'
            />
            <div className='paddingLeft05RemCls paddingRight05RemCls sendBtnCls'>
            <BiSend/>
            </div>
        </div>
    )
}

export default SohbetKutusu