import React, { useEffect } from 'react'
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'

import VideoOdasiTalepEt from '../Bilesenler/VideoOdasiTalepci'
// import { soketSunucusunaBaglan, soketSunucusundanCik, videoOdasinaGirisYap } from '../soketBaglantisi/soketBaglantisi'
// import { soketIsaretiTazele, soketiYokEt, soketOlustur } from '../soketBaglantisi/soketBaglantisi'
import { useIstemciSoketiKullan } from '../kancalar/istemciSoketiKullan'
import VideoOdalariListesi from '../Bilesenler/VideoOdalariListesi'


const VideoOdasiAna = () => {
    const { 
        kullanici, 
    } = useKimlikDogrulamaBaglami()
    const { 
        soketSunucusunaBaglantiYap, 
        soketSunucusundanAyril, 
        sunucudanKullaniciyaAitOdaListesiniAl,
        sunucudanGelenOdaListesi,
        sunucudanGelenSoket,
        } = useIstemciSoketiKullan()
    // const lanetOlasicaSoket = useMemo(() => {soketOlustur(kullanici?.isaret)}, [geciciSoket])

    useEffect(() => {
        soketSunucusunaBaglantiYap(kullanici?.isaret)
        if(kullanici?.kullanicininSocketIOSoketi) {
            console.log("kullanici?.kullanicininSocketIOSoketi: ", kullanici?.kullanicininSocketIOSoketi)
            return
        }
        else {
            // geciciPriz = soketOlustur(kullanici?.isaret)
        }
        return () => {
            console.log("SOKETI_YOK_ETME")
            // soketSunucusundanAyril()
            // soketiYokEt(geciciPriz)
            // dispatch({type: 'SOKETI_YOK_ET', payload: null}) 
            // dispatchforVideoDilimi(removeUserFromVideoRoom(kullanici))
        }
    }, [])

    useEffect(() => {
        if(kullanici?.kullanicininSocketIOSoketi) {
            console.log("kullanici?.kullanicininSocketIOSoketi: ", kullanici?.kullanicininSocketIOSoketi)
            sunucudanKullaniciyaAitOdaListesiniAl()
            return
        }
        else {
            // geciciPriz = soketOlustur(kullanici?.isaret)
        }
        // setVideoOdasindakiKullanicilar(usersInVideoRoom)
    }, [sunucudanGelenSoket])

    return (
            <section className='centerCls'>
            <VideoOdasiTalepEt/>
            <VideoOdalariListesi videoOdalari={sunucudanGelenOdaListesi || []}/>
            <div className='videoOdasiCls'>
                {/* <h1>KULLANICI: {JSON.stringify(kullanici)}</h1>
                <h1>VİDEO ODASINDAKİ LAVUKLAR: {JSON.stringify(odadakiCevrimIciKullanicilar)}</h1> */}
                {/* <h1>İSTEMCİ SOKETİ: {JSON.stringify(istemciSoketi)}</h1> */}
            </div>
            </section>
    )
}

export default VideoOdasiAna