import io from 'socket.io-client'
import { useDispatch } from "react-redux";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { removeUserFromVideoRoom, addUserToTheVideoRoom } from '../VideoOdasi/videoOdasiDilimi'
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

let istemciSoketi = null
// let sunucudanGelenOdaListesi = []

export const useIstemciSoketiKullan = () => {
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const [ sunucudanGelenOdaListesi, setSunucudanGelenOdaListesi ] = useState([])
    const [ sunucudanGelenVideoOdasindakiKullanicilar, setSunucudanGelenVideoOdasindakiKullanicilar ] = useState([])
    const [ sunucudanGelenSoket, setSunucudanGelenSoket ] = useState()
    const dispatchforVideoDilimi = useDispatch()
    const { t } = useTranslation()

    const soketSunucusunaBaglantiYap = (isaret) => {
        try {
            console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap")
            // soketIsaretiTazele(profilDegistirmeTarihi)
            /* if(istemciSoketi) {
                console.log("zaten bağlantı var: istemciSoketi: ", istemciSoketi)
                setSunucudanGelenSoket((sgs) => sgs = istemciSoketi)
                if(!istemciSoketi?.connected) {
                    console.log("burada bir sıkıntı var istemciSoketi: ", istemciSoketi)
                    istemciSoketi?.socket.reconnect()
                }
                return
            } */
            const geciciSoket = io('/', {
                retries: 3,
                ackTimeout: 10000,
                reconnectionAttempts: 10,
                withCredentials: true,
                extraHeaders: {
                    Authorization: `Bearer ${isaret}`
                }
            })
            if(geciciSoket) {
                // geciciSoket.onAnyOutgoing((olay, veri) => {
                //     console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap onAnyOutgoing olay: ", olay, "\nveri: ", veri)
                // })
                // geciciSoket.onAny((data, payload) => {
                //     console.log("useffect onAny: ", data, " payload: ", payload)
                //     console.log("useffect payload?.message: ", payload?.message)
                //     if(payload?.message === "Caylari Tazele") {
                //         soketIsaretiTazele()
                //     }
                // })
                geciciSoket.io.on("error", (data) => {
                    // console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap geciciSoket.io.on(error): ", data)
                })
                // geciciSoket.io.on("ping", (data) => {
                //     console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap geciciSoket.io.on(ping): ", data)
                // })
                const motur = geciciSoket?.io.engine
                // motur.emit("nasil_yani", {message: "Test"})
                motur.on("data", async( data ) => {
                    // console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap motur.on(\"data\" data: ", data)
                    let tmpData = data.substr(1, data.length)
                    // console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap data tmpData: ", tmpData)
                    tmpData = JSON.parse(tmpData)
                    // console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap data tmpData: ", tmpData)
                    // const mesajKismi = JSON.parse("{"+data?.split("{")[1])
                    /* if(tmpData?.message === "Caylari Tazele") {
                    // if(mesajKismi.message === "Caylari Tazele") {
                        // console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap data tmpData?.message: ", tmpData?.message)
                        await soketIsaretiTazele()
                        geciciSoket.io.Authorization = `Bearer ${kullanici?.isaret}`
                    } */
                    /* if(tmpData[1]?.message === "Caylari Tazele") {
                        // if(mesajKismi.message === "Caylari Tazele") {
                        // console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap data tmpData[1]?.message: ", tmpData[1]?.message)
                        await soketIsaretiTazele()
                        geciciSoket.io.Authorization = `Bearer ${kullanici?.isaret}`
                    } */
                })
                // setIstemciSoketi(geciciSoket)
                // istemciSoketi = geciciSoket // connect olayından sonra ancak burada soket oluyor. Aksi takdirde sürekli null
                // setSunucudanGelenSoket((sgs) => sgs = geciciSoket)
                // console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap geciciSoket: ", geciciSoket)
                // console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap istemciSoketi: ", istemciSoketi)
                geciciSoket.on('connect', () => {
                    // console.log("sunucu connect geciciSoket: ", geciciSoket)
                    istemciSoketi = geciciSoket
                    setSunucudanGelenSoket((sgs) => sgs = geciciSoket)
                    dispatch({type: 'SOKET_OLUSTUR', payload: geciciSoket?.id})
                    sunucudanKullaniciyaAitOdaListesiniAl()
                    // const kullanicininSocketIOSoketi = geciciSoket?.id
                    /* dispatchforVideoDilimi(addUserToTheVideoRoom({
                        ...kullanici, 
                        kullanicininSocketIOSoketi,
                    })) oluşturunca değil odaya girince bu çağrıyı yap*/

                }) //geciciSoket.on('connect', () => {
                    //bu kısım "connectin yani )'in dışında idi dışarıda bıraktım"
                geciciSoket.on("caylari_tazele", (sunucudanGelenVeri) => {
                console.log("caylari_tazele sunucudanGelenVeri:", sunucudanGelenVeri)
                    if(sunucudanGelenVeri.message === "Caylari Tazele") {
                        soketIsaretiTazele(kullanici?.isaret)
                    }
                })
                geciciSoket.on('disconnect', (reason, details) => {
                    // console.log("videoodası disconnect nedeni: ", reason, "\nayrıntıları: ", details)
                    dispatch({type: 'SOKETI_YOK_ET', payload: null}) 
                    dispatchforVideoDilimi(removeUserFromVideoRoom(kullanici))
                })
                geciciSoket.io.on('reconnect_attempt', () => {
                    console.log("soketSunucusunaBaglantiYap reconnect_attempt")
                })
                geciciSoket.io.on('reconnect', () => {
                    console.log("soketSunucusunaBaglantiYap reconnect")
                })
                geciciSoket.io.on('data', (data) => {
                    console.log("soketSunucusunaBaglantiYap geciciSoket.io.on data: ", data)
                })
            }
            return geciciSoket
        } catch (err) {
            console.log("useIstemciSoketiKullan soketSunucusunaBaglantiYap err: ", err)
        }
    }

    const soketIsaretiniTazelemekGerekiyorMu = async () => {
        console.log("soketIsaretiniTazelemekGerekiyorMu")
        await istemciSoketi.emit("Isaret_Yaslanmis_Mi")
    }

    const soketIsaretiTazele = async (profilDegistirmeTarihi) => {
        try {
            console.log("useIstemciSoketiKullan soketIsaretiTazele")
            const yanit = await fetch(`/isarettazele`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                },
                // body: JSON.stringify(gonderilecekVeri)
                body: ""
            })
            if (!yanit.ok) {
                if(yanit.status === 401){
                }
                else if(yanit.status === 500){
                    console.log("useIstemciSoketiKullan soketIsaretiTazele Sunucu kapalı")
                } 
                return null
            }
            else { //(!yanit.ok) {
                // console.log("profilGetir cekilenVeri.profilVerileri: ")
                const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
                console.log("useIstemciSoketiKullan soketIsaretiTazele soket isaret: ", isaret)
                let localStorageKullanici = JSON.parse(localStorage.getItem('kullanici'))
                localStorageKullanici.isaret = isaret
                localStorage.setItem("kullanici", JSON.stringify(localStorageKullanici))
                // console.log("localStorageKullanici: ", localStorageKullanici)
                dispatch({type: 'PROFILE_UPDATED', payload: localStorageKullanici})
                // window.dispatchEvent(new Event("yerelDepoDegisti")) //buna gerek yok. Bağlantı yapınca zaten güncelleniyor
                console.log("useIstemciSoketiKullan soketIsaretiTazele istemci soketi yoksa tekrar soketSunucusunaBaglanYap")
                if (!istemciSoketi){
                    console.log("useIstemciSoketiKullan soketIsaretiTazele if (!istemciSoketi){")
                    soketSunucusunaBaglantiYap(isaret)
                }
                else {
                    console.log("useIstemciSoketiKullan soketIsaretiTazele else if (!istemciSoketi){")
                    istemciSoketi.io.Authorization = `Bearer ${isaret}`
                }
                // soketOlustur(isaret)
                return isaret
            }
        } catch (err) {
            console.log("useIstemciSoketiKullan soketIsaretiTazele err: ", err)
        }
    }

    const soketSunucusundanAyril = () => {
        try {
            if (istemciSoketi) {
                console.log("soketSunucusundanAyril istemciSoketi.id: ", istemciSoketi.id)
                // setIstemciSoketi(null)
                // istemciSoketi.emit("disconnect")
                dispatchforVideoDilimi(removeUserFromVideoRoom(kullanici))
                istemciSoketi.close()
                // istemciSoketi.diconnect() 
            }
        } catch (err) {
            console.log("soketSunucusundanAyril try catch: ", err)
        }
    }

    const sunucudaOdaOlustur = async (odaAyarlari) =>{
        // const gecerliSure = +new Date()
        // const profilDegistirmeTarihi = +new Date(kullanici?.profileUpdatedOn)
        // if (gecerliSure - +new Date(profilDegistirmeTarihi) > 3000000){ // 3000000 = 50 dk.
        //     console.log("işaret tazelenmesi gerekir")
        //     await soketIsaretiTazele()
        // }
        // console.log("sunucudaOdaOlustur kullanici: ", kullanici )
        console.log("sunucudaOdaOlustur istemciSoketi: ", istemciSoketi )
        console.log("sunucudaOdaOlustur istemciSoketi?.id: ", istemciSoketi?.id )
        if(istemciSoketi?.connected){
            // console.log("sunucudaOdaOlustur istemciSoketi if(istemciSoketi){: ", istemciSoketi )
            await soketIsaretiniTazelemekGerekiyorMu()
            await istemciSoketi.emit("video_odasi_olustur", {kullanici, odaAyarlari}, istemciSoketi?.id)
            // await istemciSoketi.onAny((data, payload) => {
            //     console.log("sunucudaOdaOlustur onAny: ", data)
            // })
            await istemciSoketi.on("kullanicinin_sunucudaki_odalari", (sunucudanGelenVeri) => {
                console.log("kullanicinin_sunucudaki_odalari oluştur sunucudanGelenVeri: ", sunucudanGelenVeri)
                // sunucudanGelenOdaListesi = []
                setSunucudanGelenOdaListesi((sgol) => sgol = sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari)
                setSunucudanGelenSoket((sgs) => sgs = istemciSoketi)
                // sunucudanGelenOdaListesi = sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari
            })
        }
        else { // bağlı değil
            soketSunucusunaBaglantiYap(kullanici?.isaret)
        }
    }

    const sunucudanKullaniciyaAitOdaListesiniAl = async () =>{
        console.log("sunucudanKullaniciyaAitOdaListesiniAl istemciSoketi: ", istemciSoketi )
        console.log("sunucudanKullaniciyaAitOdaListesiniAl istemciSoketi?.id: ", istemciSoketi?.id )
        if(istemciSoketi?.connected){
            await soketIsaretiniTazelemekGerekiyorMu()
            await istemciSoketi.emit("kullaniciya_ait_video_odasi_listesini_gonder", {kullanici}, {istemciSoketi: istemciSoketi?.id})
            await istemciSoketi.on("kullanicinin_sunucudaki_odalari", (sunucudanGelenVeri) => {
                console.log("kullanicinin_sunucudaki_odalari listele sunucudanGelenVeri: ", sunucudanGelenVeri)
                console.log("kullanicinin_sunucudaki_odalari listele  sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari: ", sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari)
                setSunucudanGelenOdaListesi((sgol) => sgol = sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari)
                dispatch({type: 'VIDEO_ROOMS_CREATED_BY_THE_USER', payload: sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari})
                // dispatchforVideoDilimi(roomsCreatedByTheUser(sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari))
                toast.remove("roomshere5")
                toast.success(t('forms:usersChatRoomsListedBelowS'), {id: "roomshere5",})
                // sunucudanGelenOdaListesi = sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari
            })
        }
        else { // bağlı değil
            soketSunucusunaBaglantiYap(kullanici?.isaret)
        }
    }
    
    const odayaKatil = async (gonderilecekVeri) =>{
        console.log("odayaKatil odaIdNo: ", gonderilecekVeri )
        console.log("odayaKatil istemciSoketi: ", istemciSoketi )
        console.log("odayaKatil istemciSoketi?.id: ", istemciSoketi?.id )
        if(istemciSoketi?.connected){
            await soketIsaretiniTazelemekGerekiyorMu()
            await istemciSoketi.emit("video_odasina_katil", {kullanici, gonderilecekVeri}, istemciSoketi?.id)
            toast.remove("roomshere6")
            toast.success(t('forms:userJoiningTheChatRoomS'), {id: "roomshere6",})
            await istemciSoketi.on("video_odasindaki_kullanicilar", (sunucudanGelenVeri) => {
                console.log("video_odasindaki_kullanicilar sunucudanGelenVeri: ", sunucudanGelenVeri)
                console.log("video_odasindaki_kullanicilar sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari: ", sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari)
                setSunucudanGelenVideoOdasindakiKullanicilar((vok) => vok = sunucudanGelenVeri?.sunucudanGelenVideoOdasindakiKullanicilar)
                dispatchforVideoDilimi(addUserToTheVideoRoom(sunucudanGelenVeri))
                toast.remove("roomshere7")
                toast.success(t('forms:usersChatRoomsListedBelowS'), {id: "roomshere7",})
                // sunucudanGelenOdaListesi = sunucudanGelenVeri?.bulunanKullanicininOlusturduguSoketOdalari
            })
        }
        else { // bağlı değil
            soketSunucusunaBaglantiYap(kullanici?.isaret)
        }
    }

    const odadanAyril = async (localPeerId) => {
        if(istemciSoketi?.connected){
            await soketIsaretiniTazelemekGerekiyorMu()
            await istemciSoketi.emit("video_odasindan_ayril", localPeerId)
            toast.remove("roomshere7")
            toast.error(t('forms:userLeavingTheChatRoomS'), {id: "roomshere7",})
        }
        else { // bağlı değil
            soketSunucusunaBaglantiYap(kullanici?.isaret)
        }
    }


    /* const soketVideoOdasinaKatil = async (gonderilecekVeri) => {
        try {
            console.log("soketVideoOdasinaKatil") 
            if (istemciSoketi) {
                await soketIsaretiniTazelemekGerekiyorMu()
                istemciSoketi.emit("videoodasigiris", gonderilecekVeri)
            // console.log("videoOdasinaGirisYap socket: ", socket)
            }
        } catch (err) {
            console.log("soketVideoOdasinaKatil try catch: ", err)
        }
    } */

    return { 
        soketSunucusunaBaglantiYap, 
        soketSunucusundanAyril, 
        // soketVideoOdasinaKatil, 
        sunucudaOdaOlustur, 
        sunucudanKullaniciyaAitOdaListesiniAl,
        odayaKatil,
        odadanAyril,
        sunucudanGelenOdaListesi, 
        sunucudanGelenVideoOdasindakiKullanicilar,
        sunucudanGelenSoket,
        istemciSoketi 
    }
}