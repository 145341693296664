import React from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEpostaDogrulama } from '../kancalar/epostaDogrulamaKullan';
import { DevTool } from '@hookform/devtools';
import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici';

import '../Stylelar/main.css'

export const EpostaDogrula = () => {
    const { t } = useTranslation()
    // const yonlendir = useNavigate()
    const { demoState} = useGenelAyarlarBaglami()
    const {
            register, 
            handleSubmit, 
            control,
            // watch,
            // setError, 
            formState: 
            {errors, 
                // isSubmitting
            } 
        } = useForm({
            mode: 'onChange'
        })

    // const { kullanici } = useKimlikDogrulamaBaglami()

    const { epostaDogrula, yukluyor } = useEpostaDogrulama()

    const submitEpostaDogrula = async (data) => {
        try {
            epostaDogrula(data)
        } catch (err) {
            console.log("catch err: ", err)
            if (err.message.includes('NetworkError')){
                // toast.remove("roomshere5") ???
                // toast.error(t("flashMessages:networkErrorS"))
                // setHataMesaji(t("flashMessages:networkErrorS"))
            }
        }
    }
    const onError = (errors) => {
        // setHataMesaji(`Hata on Errordan: ${errors}`)
        console.log("errors: ", errors)
    }

    return (
        <section className='centerCls'>
        {/* <YonlendirenBilesen/> */}
        <h1>{t('genericStrings:verifyEmailS')}</h1>
        <form 
        className='formCls' 
        onSubmit={handleSubmit(submitEpostaDogrula, onError)}
        noValidate
        >
        {/* {hataMesaji && <div>{t("flashMessages:errorS")}{ hataMesaji}</div>} */}
            <div className="formControlCls">
                <label htmlFor='dogrulamaKodu'>{t('forms:verificationCodeS')}</label>
                <input 
                    type='text'
                    name='dogrulamaKodu'
                    id='dogrulamaKodu'
                    // ref={emailRef}
                    {...register(
                        "dogrulamaKodu", {
                            required: {value: true, 
                            message: t('messages:thisFieldIsRequiredS')},
                            minLength: {
                                value: 60,
                                message: t('messages:verificationCodeMinLengthS')
                            }
                        }
                    )}
                />
                {errors.dogrulamaKodu && <div className='redSpanCls'>{t('messages:verificationCodeMinLengthS')} </div>}
            </div>
            <div className='formControlCls'>
                    <button className='greenBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem' 
                    // disabled={!gecerliEposta || !gecerliSifre || !sifrelerUyuyor ? true : false}
                    >
                    {yukluyor ? t('forms:waitFormS') : t('genericStrings:verifyEmailS')}
                    </button>
            </div>
        </form>
        { demoState && <DevTool control={control}/> }
    </section>
    )
}
