import React from 'react'
import { DevTool } from '@hookform/devtools'
import { useForm } from 'react-hook-form' 
import { useTranslation } from 'react-i18next';

import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici';
import { EMAIL_REGEX, SIFRE_REGEX } from '../yararli/utils';
import AnimasyonluKarakterler from './AnimasyonluKarakterler';
import { useSifreDegistir } from '../kancalar/sifreDegistirKullan';

import './BeklemeBileseni.css'
import '../Stylelar/main.css'

const SifreDegistirmeFormu = ({ emailFromProfile, sifreDegistirmeFormunuAcKapa }) => {
    const { demoState } = useGenelAyarlarBaglami();
    const { t } = useTranslation();
    const { 
        yukluyor,
        sifreDegistir,
    } = useSifreDegistir()

    const {
            register, 
            control,
            handleSubmit, 
            watch,
            setValue,
            setError, 
            formState
        } = useForm({
            mode: "onChange"
        })
    const {errors} = formState

    const sifreAciklamaMesaji = t('messages:passwordMinLengthS:line1')+' - '+t('messages:passwordMinLengthS:line2') +' - '+t('messages:passwordMinLengthS:line3')
    const teyitSifreAciklamaMesaji = t('flashMessages:PasswordsMustMatchFS')

    const submitSifreDegistir = async (data) => {
        // console.log("signup data: ", data)
        try {
            // return console.log("GEÇİCİ DEVRE DIŞI")
            await sifreDegistir(data)
        } catch (err) {
            console.log(err)
        }
    }

    const onError = (errors) => {
        console.log("errors: ", errors)
    }
    const yenisifre = watch("yenisifre")

    return (
        <div className='fullScreenCls'>
            <h1 className='centerTextCls alignBottomGridCls'>{t('forms:changePasswordS')}</h1>
            <form 
            className='formCls' 
            onSubmit={handleSubmit(submitSifreDegistir, onError)}
            noValidate
            >
            <div className='sagaDayaliKapatmaDugmesiCls2' onClick={sifreDegistirmeFormunuAcKapa}>X</div>
            <div className='formControlCls'>
            <label htmlFor='email'>{t('forms:useremailFormFieldS')} 
                {emailFromProfile}
                </label>
                <input
                    type='text'
                    id='email'
                    name='email'
                    hidden
                    value={emailFromProfile}
                    {...register(
                        "email", {
                            required: {
                                value: true, 
                                message: t('messages:thisFieldIsRequiredS')
                            },
                            pattern: {
                                value: EMAIL_REGEX,
                                message: t('messages:enterAValidEmailS')
                                }
                        }
                    )}
                />
            </div>
            <div className='formControlCls'>
                <label className='redspanCls' htmlFor='eskisifre'>{t('forms:userOldPasswordFormFieldS')} 
                </label>
                <input
                    type='password'
                    id='eskisifre'
                    name='eskisifre'
                    {...register(
                            "eskisifre", {
                            required:
                            {value: true, 
                            message: t('messages:enterAPasswordS')
                            },
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            }
                        }
                    )}
                />
                {errors.eskisifre && <div className='redSpanCls'>{sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
            <div className='formControlCls'>
                <label htmlFor='yenisifre'>{t('forms:usersNewPasswordFormFieldS')} 
                </label>
                <input
                    type='password'
                    id='yenisifre'
                    name='yenisifre'
                    {...register(
                            "yenisifre", {
                            required:
                            {value: true, 
                            message: t('messages:enterAPasswordS')
                            },
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            }
                        }
                    )}
                />
                {errors.yenisifre && <div className='redSpanCls'>{sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
            <div className='formControlCls'>
                <label htmlFor='teyitSifresi'>{t('forms:userpasswordForConfirmationFormFieldS')}
                </label>
                <input
                    type='password'
                    id='teyitSifresi'
                    name='teyitSifresi'
                    // ref={teyitSifresiRef}
                    aria-describedby='teyitSifrenote'
                    // {...register('teyitSifresi')}
                    {...register(
                            "teyitSifresi", {
                            required: 
                            {value: true, 
                            message: t('messages:confirmThePasswordS')
                            },
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            },
                            validate: (val) => {
                                console.log("val: ", val)
                                console.log("yenisifre: ", yenisifre)
                                if(val !== yenisifre) {setError("teyitSifresi")}
                                }
                        }
                    )}
                />
                {errors.teyitSifresi && <div className='redSpanCls'>{ teyitSifreAciklamaMesaji } {sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
            <div className='formControlCls'>
                <button className='greenBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem' 
                // disabled={yukluyor}
                type="submit"
                >{yukluyor ? t('forms:waitFormS') : t('forms:changePasswordS')}
                </button>
                {/* disabled={isSubmitting}
                >{isSubmitting ? t('forms:waitFormS') : t('forms:registerFormFieldS')}</button> */}
            </div>
            </form>
            { demoState && <DevTool control={control}/> }
        </div>
    )
}

export default SifreDegistirmeFormu