import React from 'react'

import BaglantiliDugme from './BaglantiliDugme'
import { useIstemciSoketiKullan } from '../kancalar/istemciSoketiKullan'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

const VideoOdasiTalepEt = () => {
    const {
            register, 
            handleSubmit,
            formState: 
            {
                errors, 
                // isSubmitting
            } 
        } = useForm({
            mode: 'onChange'
        })
    
    const {
            sunucudaOdaOlustur,
            } = useIstemciSoketiKullan()
            
    const { t } = useTranslation()

    const submitOdaOlustur = async (odaAyarlari) => {
        try {
            // console.log(data)
            sunucudaOdaOlustur(odaAyarlari)
        } catch (err) {
            console.log("catch err: ", err)
            if (err.message.includes('NetworkError')){
            }
        }
    }
    const onError = (errors) => {
        // setHataMesaji(`Hata on Errordan: ${errors}`)
        console.log("errors: ", errors)
    }

    return (
        <>
        <form 
            className='formCls' 
            onSubmit={handleSubmit(submitOdaOlustur, onError)}
            noValidate
            >   
                <div className="formControlCls">
                    <label htmlFor='odaAdi'>
                    {t('forms:roomNameOptionalS')}
                    </label>
                    <input 
                        type='text'
                        name='odaAdi'
                        id='odaAdi'
                        {...register(
                            "odaAdi"
                        )}
                    />
                </div>
                <div className="formControlCls">
                    <label htmlFor='kaliciOda'>
                    {t('forms:permanentCheckBoxS')} 
                    </label>
                        <input
                            type='checkbox'
                            id='kaliciOda'
                            // onChange={(e) => setSifre(e.target.value)}
                            {...register(
                                "kaliciOda"
                            )}
                        />
                </div>
                <div className="formControlCls">
                    <label htmlFor='ozelOda'>
                    {t('forms:privateCheckBoxS')} 
                    </label>
                        <input
                            type='checkbox'
                            id='ozelOda'
                            // onChange={(e) => setSifre(e.target.value)}
                            {...register(
                                "ozelOda"
                            )}
                        />
                </div>
                <div className="formControlCls">
                    <button 
                    className='redBackgroundCls roundedCornersCls paddingSides5Rem' 
                    type='submit'>
                    {t("genericStrings:requestRoomS")}
                    </button>
                    {/* <BaglantiliDugme 
                    classes='redBackgroundCls roundedCornersCls paddingSides5Rem' 
                    // clickEvent={sunucudaOdaOlustur}
                    >
                    {t("genericStrings:requestRoomS")}
                    </BaglantiliDugme> */}
                </div>
        </form>
        </>
    )
}

export default VideoOdasiTalepEt