import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { useProfilGetir } from '../kancalar/profilGetirKullan'
import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici'
import { EMAIL_REGEX } from '../yararli/utils'
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useProfilGuncelle } from '../kancalar/profilGuncelleKullan'
import BaglantiliDugme from './BaglantiliDugme'
import VideoOdalariListesi from './VideoOdalariListesi'
import { useIstemciSoketiKullan } from '../kancalar/istemciSoketiKullan'
import './ProfilGuncelleme.css'
import SifreDegistirmeFormu from './SifreDegistirmeFormu'
import { NavLink } from 'react-router-dom'

const ProfilGuncelleme = () => {
    const { 
        sunucudanKullaniciyaAitOdaListesiniAl,
        sunucudanGelenOdaListesi,
        sunucudanGelenSoket,
    } = useIstemciSoketiKullan()
    const { kullanici } = useKimlikDogrulamaBaglami()
    const { yukluyor, 
        getirilenProfil, 
        profiliGosterme, 
        profilGetir, 
    } = useProfilGetir()
    const { profilGuncelle, guncellenenProfil } = useProfilGuncelle()
    // const [ simdikiAn, setSimdikiAn ] = useState(new Date())
    const [ sifreDergistirmeFormuGorunuyor, setSifreDergistirmeFormuGorunuyor ] = useState(false)
    // console.log("getirilenProfil: ", getirilenProfil)
    const { demoState} = useGenelAyarlarBaglami();
    const { t } = useTranslation()
    const {
            register, 
            handleSubmit, 
            reset,
            // watch,
            // setError, 
            // setValue,
            control,
            formState: 
            {errors
                // isSubmitting
            } 
        } = useForm({
            mode: 'onChange',
            defaultValues: { 
                "id": "", 
                "username": "",
                "email": "",
                "recoveryEmail": "",
                "mobilePhone": "",
                "authenticationType": "",
                "toBeRedirectedTo": "",
                "messageWithRedirectionTo": "",
                "profileVisibility": "",
                "description": "",
                "kullanicituruId": "",
            }
        })

    // const { isDirty, dirtyFields} = useFormState( {control} )

    const sifreAciklamaMesaji = t('messages:passwordMinLengthS:line1')+' - '+t('messages:passwordMinLengthS:line2') +' - '+t('messages:passwordMinLengthS:line3')

    const onError = (errors) => {
        // setHataMesaji(`Hata on Errordan: ${errors}`)
        console.log("errors: ", errors)
    }

    useEffect(() => {
            // console.log("Hesabim useEffect kullanici: ", JSON.stringify(kullanici))
            // console.log("Hesabim useEffect kullanici.isaret: ", kullanici?.isaret)
            // console.log("ProfilGuncelleme useEffect editMode: ", editMode)
            profilGetir(kullanici)
            // setSimdikiAn((sa) => sa = +(new Date()))
    }, [kullanici?.email, guncellenenProfil])
    
    useEffect(() => {
            // console.log("Hesabim useEffect kullanici: ", JSON.stringify(kullanici))
            // console.log("Hesabim useEffect kullanici.isaret: ", kullanici?.isaret)
            reset({
                username: getirilenProfil?.username,
                kullanici_slugi: getirilenProfil?.kullanici_slugi,
                email: getirilenProfil?.email,
                recoveryEmail: getirilenProfil?.recoveryEmail,
                mobilePhone: getirilenProfil?.mobilePhone,
                authenticationType: getirilenProfil?.authenticationType,
                toBeRedirectedTo: getirilenProfil?.toBeRedirectedTo,
                messageWithRedirectionTo: getirilenProfil?.messageWithRedirectionTo,
                // profilDuzenlemesiniErtele: getirilenProfil?.profilDuzenlemesiniErtele,
                profileVisibility: getirilenProfil?.profileVisibility,
                description: getirilenProfil?.description,
                kullanicituruId: getirilenProfil?.kullanicituruId,
            })
    }, [getirilenProfil])

    /* useEffect(() => {
        sunucudanKullaniciyaAitOdaListesiniAl() //bu işlemi burada yapma tekrara giriyor
    }, []) */

    const sifreDegistirmeFormunuAcKapa = () => {
        setSifreDergistirmeFormuGorunuyor((gorunur) => !gorunur)
    }

    return  (
        <div className='profilGuncellemeCls'>
            { 
                !profiliGosterme ? (
                <>
                {sifreDergistirmeFormuGorunuyor && <SifreDegistirmeFormu 
                emailFromProfile={kullanici?.email} 
                sifreDegistirmeFormunuAcKapa={sifreDegistirmeFormunuAcKapa}/>}
                <h1 className='formGridCls' >{t('genericStrings:myAccountS')}</h1>
                {/* { JSON.stringify(kullanici)} */}
                <form 
                className='formGridCls' 
                onSubmit={handleSubmit(profilGuncelle, onError)}
                noValidate
                >
                <h1>
                <NavLink to={"/videoodasi"}>
                    <span className='redspanCls'>
                    {t('forms:usersChatRoomsS')}
                    </span>
                </NavLink>
                </h1>
                <h1>ŞİFRE DEĞİŞTİRMEYİ HALLETTİM. ANCAK SOKETLERDE İŞARET NASIL GÜNCELLENECEK</h1>
                    <div className="formControlGridCls">
                        <label htmlFor='id'>{t('forms:userIdFormFieldS')}</label>
                        <input 
                            type='text'
                            name='id'
                            id='id'
                            disabled
                            value={getirilenProfil?.id}
                        />
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='username'>{t('forms:usernameFormFieldS')}</label>
                        <input 
                            type='text'
                            name='username'
                            id='username'
                            {...register(
                                "username"
                            )}
                        />
                        {errors.username && <div className='redSpanCls'>{t('messages:enterAValidEmailS')} </div>}
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='kullanici_slugi'>{t('forms:userSlugFormFieldS')}</label>
                        <input 
                            type='text'
                            name='kullanici_slugi'
                            id='kullanici_slugi'
                            disabled
                            {...register(
                                "kullanici_slugi"
                            )}
                        />
                        {errors.kullanici_slugi && <div className='redSpanCls'>{t('messages:enterAValidEmailS')} </div>}
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='email'>{t('forms:useremailFormFieldS')}</label>
                        <input 
                            type='email'
                            name='email'
                            id='email'
                            disabled
                            {...register(
                                "email", {
                                    required: {value: true, 
                                    message: t('messages:thisFieldIsRequiredS')},
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: t('messages:enterAValidEmailS')
                                        }
                                }
                            )}
                        />
                        {errors.email && <div className='redSpanCls'>{t('messages:enterAValidEmailS')} </div>}
                    </div>
                    <div className="formControlGridCls greenBackgroundCls"
                        data-tooltip-id="delayForOneMonthToolTip"
                        data-tooltip-content={t('forms:profileVisibilityFormFieldS')}>
                        <label htmlFor='profileVisibility'
                            >{t('forms:profileVisibilityFormFieldS')}</label>
                        <select 
                            type='text'
                            name='profileVisibility'
                            id='profileVisibility'
                            {...register(
                                "profileVisibility"
                            )}
                        >
                            <option value="0">{t('forms:profileVisibilityInvisibleS')}</option>
                            <option value="1">{t('forms:profileVisibilityVisibleToFriendsS')}</option>
                            <option value="2">{t('forms:profileVisibilityPublicS')}</option>
                        </select>
                        <Tooltip id='delayForOneMonthToolTip'/>
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='recoveryEmail'>{t('forms:recoveryEmailFormFieldS')}</label>
                        <input 
                            type='email'
                            name='recoveryEmail'
                            id='recoveryEmail'
                            {...register(
                                "recoveryEmail", {
                                    // required: {value: true, 
                                    // message: t('messages:thisFieldIsRequiredS')},
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: t('messages:enterAValidEmailS')
                                        }
                                }
                            )}
                        />
                        {errors.recoveryEmail && <div className='redSpanCls'>{t('messages:enterAValidEmailS')} </div>}
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='mobilePhone'>{t('forms:mobilePhoneFormFieldS')}</label>
                        <input 
                            type='text'
                            name='mobilePhone'
                            id='mobilePhone'
                            {...register(
                                "mobilePhone"
                            )}
                        />
                        {errors.mobilePhone && <div className='redSpanCls'>{t('messages:enterAValidEmailS')} </div>}
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='authenticationType'>{t('forms:signInTypeFormFieldS')}</label>
                        <select 
                            type='text'
                            name='authenticationType'
                            id='authenticationType'
                            {...register(
                                "authenticationType"
                            )}
                        >
                            <option value="1">{t('forms:regularLoginOptionS')}</option>
                            <option value="2">{t('forms:twoFAOptionS')}</option>
                        </select>
                        {errors.authenticationType && <div className='redSpanCls'>{t('messages:enterAValidEmailS')} </div>}
                    </div>
                    <div className="formControlGridCls">
                        <label>
                        {t('forms:changePasswordS')} 
                        </label>
                        <button onClick={sifreDegistirmeFormunuAcKapa} type="button" className='redBackgroundCls roundedCornersCls paddingSides5Rem' 
                        >
                        {yukluyor ? t('forms:waitFormS') : t('forms:changePasswordS')}
                        </button>
                    </div>
                    <div className="formControlGridCls">
                        <label>
                        {t('forms:requestPasswordResetCodeS')} 
                        </label>
                        <BaglantiliDugme classes= 'yellowBackgroundCls roundedCornersCls paddingSides5Rem' to={"/sifresifirlamatalepet"}>
                        {yukluyor ? t('forms:waitFormS') : t('forms:requestPasswordResetCodeS')}
                        </BaglantiliDugme>
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='toBeRedirectedTo'>
                        {t('forms:yonlendirmeYapilacakYolFormFieldS')} 
                        </label>
                        <input
                            type='text'
                            name='toBeRedirectedTo'
                            id='toBeRedirectedTo'
                            disabled
                            {...register(
                                "toBeRedirectedTo"
                            )}
                        />
                        {errors.toBeRedirectedTo && <div className='redSpanCls'></div>}
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='messageWithRedirectionTo'>
                        {t('forms:yonlendirmeMesajiFormFieldS')} 
                        </label>
                        <input
                            type='text'
                            name='messageWithRedirectionTo'
                            id='messageWithRedirectionTo'
                            disabled
                            {...register(
                                "messageWithRedirectionTo"
                            )}
                        />
                        {errors.messageWithRedirectionTo && <div className='redSpanCls'></div>}
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='description'>
                        {t('forms:usersDescription')} 
                        </label>
                        <input
                            type='textarea'
                            name='description'
                            id='description'
                            {...register(
                                "description"
                            )}
                        />
                        {errors.description && <div className='redSpanCls'></div>}
                    </div>
                    <div className="formControlGridCls">
                        <label htmlFor='id'>{t('forms:userIdFormFieldS')}</label>
                        <input 
                            type='text'
                            name='kullanicituruId'
                            id='kullanicituruId'
                            disabled
                            value={getirilenProfil?.kullanicituruId}
                        />
                    </div>
                    {/* <div className="formControlGridCls">
                        <label htmlFor='sifre'>
                        {t('forms:userpasswordFormFieldS')} 
                        </label>
                        <input
                            type='password'
                            id='sifre'
                            {...register(
                                "sifre", {
                                required: {value: true, 
                                message: t('messages:enterAPasswordS')},
                                pattern: {
                                    value: SIFRE_REGEX,
                                    message: "aciklamaMesaji"
                                }
                            }
                            )}
                        />
                        {errors.sifre && <div className='redSpanCls'>{sifreAciklamaMesaji} <AnimasyonluKarakterler/></div>}
                    </div> */}
                    <div className='formControlGridCls'>
                        <button 
                        className='greenBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem' 
                        // disabled={errors}
                        >
                        {yukluyor ? t('forms:waitFormS') : t('forms:saveChangesS')}
                        </button>
                    </div>
                </form>
                { demoState && <DevTool control={control}/> }
                {/* <VideoOdalariListesi videoOdalari={sunucudanGelenOdaListesi || []}/> */}
                </>
            ) : null 
            }
        </div>
    )
}

export default ProfilGuncelleme