import React from 'react'
import { Link } from 'react-router-dom'

import './demo.css' 
import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici'
console.log("Yukarıdaki kısmı devreye al")

function Demo() {
    const { demoState,
        gecerliHost,
        } = useGenelAyarlarBaglami()
    return (
        <div className='demoDivCls'>
            { gecerliHost.includes("localhost") && <>
            {demoState && <h1>Responsive halini de ele al. Menüyü dokunmatik hale getir.</h1>}
            {demoState && <h1>Form doğrulama Signinde tamamlanmamış gibi. Şimdi profili doldur.</h1> }
            {demoState && <h1 className='redspanCls'>Soket kısmında kaldım.</h1> }
            {demoState && <Link className='redspanCls' to={"emaildogrulama"}>emaildogrulama</Link>}
            {demoState && <Link className='redspanCls' to={"hesabim"}>hesabim</Link>}
            {demoState && <Link className='redspanCls' to={"sifresifirla"}>sifresifirla</Link>}
            {demoState && <Link className='redspanCls' to={"sifresifirlamatalepet"}>sifresifirlamatalepet</Link>}
            {demoState && <Link className='redspanCls' to={"videoodasi"}>videoodasi</Link>}
            {demoState && <Link className='redspanCls' to={"videoodasi/ac0f87bd-7c0e-467a-a87e-849ed14a3313"}>videoodasi oda</Link>}
            <br/>
            {demoState && <Link className='redspanCls' to={"sacmalardansecmeler"}>sacmalardansecmeler</Link>}
            {demoState && <Link className='redspanCls' to={"signin"}>signin</Link>}
            {demoState && <Link className='redspanCls' to={"signin/sonrasil@kilavuz-tercume.com"}>signin/sonrasil@kilavuz-tercume.com</Link>}
            {demoState && <Link className='redspanCls' to={"signup"}>signup</Link>}
            <br/>
            {demoState && <Link className='redspanCls' to={"signup/sonrasil@kilavuz-tercume.com"}>signup/sonrasil@kilavuz-tercume.com</Link>}
            {demoState && <Link className='redspanCls' 
            to={"signup/translation-client-non-certified-written-translation/Onay gerektirmeyen yazılı çeviri yaptırmak istiyorum"}>
            signup/translation-client-non-certified-written-translation/Onay gerektirmeyen yazılı çeviri yaptırmak istiyorum</Link>}
            </>}
            <h1>YAPIM HALİNDE - UNDER CONSTRUCTION - В разработке! - Bajo construcción</h1>
            <h1>LÜTFEN KAYIT OLMAYIN - PLEASE DO NOT REGISTER - Не регистрируйтесь! - No te registres</h1>
        </div>
    )
}

export default Demo