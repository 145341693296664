import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'

import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici'

import Navbar from '../Bilesenler/Navbar'
import Footer from '../Bilesenler/Footer'
import Demo from '../Bilesenler/Demo'
import EkmekKirintilari from '../Bilesenler/EkmekKirintilari'
// import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'

function KokYerlesim() {
    const { demoState,
        setGenelAyarlarSimdikiAn,
        gecerliHost,
    } = useGenelAyarlarBaglami()


    useEffect(() => {
        setGenelAyarlarSimdikiAn((sa) => sa = +new Date())
    }, [])

    // window.addEventListener(("yerelDepoDegisti"), () => {
    //     console.log("Kökyerleşim yerelDepoDegisti")
    // }) //buna gerek yok. Bağlantı yapınca zaten güncelleniyor

    return (
        <div id="mainBodyId" className='kokYerlesim'>
            <Navbar/>
            <main className='homePage'>
                <section className='mainSection'>
                    {/* <YonlendirenBilesen /> */}
                    <EkmekKirintilari/>
                    {demoState && <Demo/>}
                    <ErrorBoundary>
                    <div className='cikisKismiCls'>
                        <Outlet/>
                    </div>
                    </ErrorBoundary>
                    {/* <SignUp/> */}
                </section>
            </main>
            <Toaster position="top-left" />
            <Footer/>
        </div>
    )
}

export default KokYerlesim