import { createSlice } from "@reduxjs/toolkit";

const baslangicDurumu = {
    odadakiCevrimIciKullanicilar: [],
    kullanicininIcindeOlduguOdalar: [],
    kullaniciyaAitOdalar: [],
};

/* if(baslangicDurumu.istemciSoketi) {
    baslangicDurumu.istemciSoketi.on("connect", () => {
        console.log("baslangicDurumu.istemciSoketi: ", baslangicDurumu.istemciSoketi)
    })
} */


export const videoOdasiDilimi = createSlice({
    name: "videoodasi",
    initialState: baslangicDurumu,
    reducers: 
    {   
        usersInVideoRoom : (state) => state.odadakiCevrimIciKullanicilar = "O bu",
        // usersInVideoRoom : (state) => state.odadakiCevrimIciKullanicilar,
        roomsCreatedByTheUser: (state, action) => {
            state.kullaniciyaAitOdalar.push(action.payload)
            /* if(state.kullaniciyaAitOdalar.length === 0){
                state.kullaniciyaAitOdalar[0] = action.payload
            }
            else {
                state.kullaniciyaAitOdalar.push(action.payload)
            } */
        },
        addUserToTheVideoRoom: (state, action) => {
            console.log( "addUserToTheVideoRoom action.payload?.kullanici.kullaniciyaAitVideoOdalari: ", 
                action.payload?.kullanici.kullaniciyaAitVideoOdalari)
            if(!state.odadakiCevrimIciKullanicilar.find(
                (eklenenKullanici) => eklenenKullanici === action.payload?.kullanici
            )){
                state.odadakiCevrimIciKullanicilar.push(action.payload?.kullanici)
            }
            // state.odadakiCevrimIciKullanicilar.push(action.payload?.kullanici)
        },
        removeUserFromVideoRoom: (state, action) => {
            console.log("removeUserFromVideoRoom Çıkarıyor mu? action.payload: ", action.payload)
            // console.log("removeUserFromVideoRoom state.odadakiCevrimIciKullanicilar before ", JSON.stringify(state.odadakiCevrimIciKullanicilar))
            state.odadakiCevrimIciKullanicilar = 
            state.odadakiCevrimIciKullanicilar.filter(
                (kull) => {
                    if(kull === action.payload){
                        return true
                    }
                    return false
                }
            )
        // console.log("removeUserFromVideoRoom action.payload ", action.payload)
        },
    },
});

// console.log("videoOdasiDilimi: ", videoOdasiDilimi)


export const { 
    addUserToTheVideoRoom, 
    removeUserFromVideoRoom, 
    usersInVideoRoom, 
    roomsCreatedByTheUser,
    } = videoOdasiDilimi.actions;

export default videoOdasiDilimi.reducer;
