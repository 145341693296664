import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'

const Korunan = () => {
    const { kullanici } = useKimlikDogrulamaBaglami()
    return (
        <>
            {kullanici ? <Outlet/> : <Navigate to={'/'}/>}
        </>
    )
}

export default Korunan