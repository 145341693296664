import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";
import { useIsaretTazele } from "./isaretTazeleKullan";
import { useProfilGetir } from "./profilGetirKullan";

export const useProfilGuncelle = () => {
    const { t } = useTranslation();
    const [ guncellenenProfil, setGuncellenenProfil] = useState(false)
    const { yukluyor, profilGetir } = useProfilGetir()
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte } = useGenelAyarlarBaglami()
    const {isaretTazele} = useIsaretTazele()

    const profilGuncelle = async (data) => {
        try {
            // setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:ProfilGuncelleniyorFS"),
                beklesin: true})
            const isarettmp = kullanici?.isaret
            console.log("profilGuncelle data: ", data)
            // console.log("profilGuncelle data.isaret: ", data.isaret)
            if(typeof(isarettmp) === "undefined"){
                console.log("profilGuncelle buraya geliyor mu")
                // setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilGuncelleniyorFS"),
                    beklesin: false})
                return
            }
            const yanit = await fetch(`/profilguncelleapi`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + isarettmp,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            // console.log("profili getir ulan")
            // console.log("profilGuncelle yanit: ", yanit)
            const guncellenecekVeri = await yanit.json()
            if (!yanit.ok) {
                if(yanit.status === 401){
                    if(guncellenecekVeri?.sonuc === "Yassah Ki Yassah") {
                        toast.remove("profilGuncelleKullan1")
                        toast.error(t("flashMessages:PleaseLogInFS"), {id: "profilGuncelleKullan1"})
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else if ((guncellenecekVeri?.sonuc === "Caylari Tazele") ){
                        isaretTazele()
                    }
                    else if ((guncellenecekVeri?.sonuc === "Tekrar Giris Yap") ){
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else if ((guncellenecekVeri?.sonuc === "Sifre Sifirla") ){
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else if ((guncellenecekVeri?.sonuc === "Bu Kullanici Adini Kullanamazsın") ){
                        toast.remove("profilGuncelleKullan2")
                        toast.error(t("flashMessages:UserNameAlreadyInUseFS"), {id: "profilGuncelleKullan2"})
                        setGuncellenenProfil((gg) => !gg )
                        // localStorage.removeItem('kullanici')
                        // dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else {
                        toast.remove("profilGuncelleKullan3")
                        toast.error(t("flashMessages:notAuthorizedToDoTheActionFS"), {id: "profilGuncelleKullan3"})
                    }
                }
                else if(yanit.status === 500){
                    toast.remove("profilGuncelleKullan4")
                    toast.error(t("flashMessages:server500ErrorFS"), {id: "profilGuncelleKullan4"})
                }
                // setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilGuncelleniyorFS"),
                    beklesin: false})
            }
            else { //(!yanit.ok) {
                // console.log("profilGuncelle guncellenecekVeri.profilVerileri: ", guncellenecekVeri.profilVerileri)
                if(guncellenecekVeri?.sonuc === "Profil Guncellendi"){
                    const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
                    let sonVeri = {}
                    sonVeri["message"] = guncellenecekVeri.message
                    sonVeri["username"] = guncellenecekVeri.profilVerileri.username
                    sonVeri["email"] = guncellenecekVeri.profilVerileri.email
                    sonVeri["kullanici_slugi"] = guncellenecekVeri.profilVerileri.kullanici_slugi
                    sonVeri["toBeRedirectedTo"] = guncellenecekVeri.profilVerileri.toBeRedirectedTo
                    sonVeri["messageWithRedirectionTo"] = guncellenecekVeri.profilVerileri.messageWithRedirectionTo
                    sonVeri["profileUpdatedOn"] = guncellenecekVeri.profilVerileri.profileUpdatedOn
                    /* sonVeri["profilDuzenlemesiniErtele"] = guncellenecekVeri.profilVerileri.profilDuzenlemesiniErtele
                    sonVeri["ertelemeSuresi"] = guncellenecekVeri.profilVerileri.ertelemeSuresi */
                    sonVeri["isaret"] = isaret
                    // veri = {...veri, isaret}
                    console.log("Güncelle sonVeri: ", sonVeri)
                    // localStorage.setItem('kullanici', JSON.stringify(sonVeri))
                    dispatch({type: 'PROFILE_UPDATED', payload: sonVeri})
                    toast.remove("profilGuncelleKullan5")
                    toast.success(t("flashMessages:ProfilBasariylaGuncellendiFS"), {id: "profilGuncelleKullan5"})
                    setGuncellenenProfil((gg) => !gg )
                }
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilBasariylaGuncellendiFS"),
                    beklesin: false})
                // setYukluyor(false)
            }
        } catch (err) {
            console.log(err)
            // setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:ProfilGuncelleniyorFS"),
                beklesin: false})
        }
    }

    return { profilGuncelle, yukluyor, guncellenenProfil }
}