import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import { MdCancel } from "react-icons/md";
import { NavLink } from 'react-router-dom'

const VideoOdalariListesi = ({ videoOdalari }) => {
    const { t } = useTranslation()
    return (
        <div className='formGridCls'>
            <h1 id="kullanicinin_odalari">{ t('forms:usersChatRoomsS')}</h1>
            <ul>
            { 
                videoOdalari.length === 0 ? 
                <li className='redspanCls navBarDropDownCls'>
                {t("genericStrings:noVideoChatRoomFoundS")}
                </li>
                : videoOdalari?.map((videoOdasi) => {
                return (<li key={videoOdasi.id} className='navBarDropDownCls'> <strong>
                    <NavLink className='redspanCls' to={"/videoodasi/"+videoOdasi.room_id}>
                        {videoOdasi.room_name} - {videoOdasi.room_id} - 
                        { t('forms:permanentCheckBoxS')} - 
                        {videoOdasi.permanent ? <FaCheck className='greenBackgroundCls'/> : <MdCancel className='greenBackgroundCls'/>}
                        { t('forms:privateCheckBoxS')} - 
                        {videoOdasi.private ? <FaCheck className='greenBackgroundCls'/> : <MdCancel className='greenBackgroundCls'/>}
                        {/* {videoOdasi.permanent ? (t('forms:permanentCheckBoxS')) (<FaCheck/>) : null} */}
                    </NavLink> 
                </strong>
                </li>)
                })}
            </ul>
        </div>
    )
}

export default VideoOdalariListesi