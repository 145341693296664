import { createSlice } from "@reduxjs/toolkit";

const baslangicDurumu = {
    sohbetKutulari: [],
    sohbetGecmisi: {},
};

/* if(baslangicDurumu.istemciSoketi) {
    baslangicDurumu.istemciSoketi.on("connect", () => {
        console.log("baslangicDurumu.istemciSoketi: ", baslangicDurumu.istemciSoketi)
    })
} */


export const videoOdasiSohbetleriDilimi = createSlice({
    name: "videoodasisohbetleri",
    initialState: baslangicDurumu,
    reducers: 
    {   
        addChatBox: (state, action) => {
            if(!state.sohbetKutulari.find((sohbetKutusu) => sohbetKutusu.soketId === action.payload.soketId)){
                state.sohbetKutulari.push(action.payload)
            }
        },
        removeChatBox: (state, action) => {
            state.sohbetKutulari = 
            state.sohbetKutulari.filter((sohbetKutusu) => sohbetKutusu.soketId !== action.payload)
        },
        addChatMessage: (state, action) => {
            if(state.sohbetGecmisi[action.payload.soketId]){
                //soketId var mesajı ekle
                state.sohbetGecmisi[action.payload.soketId].push(
                    {
                        mesajIdsi: action.payload.mesajIdsi,
                        mesajIcerigi: action.payload.mesajIcerigi,
                        benimMesajim: action.payload.benimMesajim,
                    }
                )
            }
            else { // if(state.sohbetGecmisi[action.payload.soketId]){
                state.sohbetGecmisi[action.payload.soketId] = [
                    {
                        mesajIdsi: action.payload.mesajIdsi,
                        mesajIcerigi: action.payload.mesajIcerigi,
                        benimMesajim: action.payload.benimMesajim,
                    }
                ]
                //sokeetId yok
            } // else // if(state.sohbetGecmisi[action.payload.soketId]){
        },
    },
});

// console.log("videoOdasiDilimi: ", videoOdasiDilimi)


export const { 
    addChatBox, 
    removeChatBox, 
    addChatMessage, 
    } = videoOdasiSohbetleriDilimi.actions;

export default videoOdasiSohbetleriDilimi.reducer;
