import React from 'react'

import ProfilGuncelleme from './ProfilGuncelleme'

const Hesabim = () => {

    return (
        <>
        <ProfilGuncelleme />
        {/* {kullanici && <ProfilGuncelleme />} */}
        </>
    )
}

export default Hesabim