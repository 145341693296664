import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useSifreDegistir = () => {
    const { t } = useTranslation();
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const { 
        kabulEdilenDil, 
        setBeklemekte,
    } = useGenelAyarlarBaglami()
    const yonlendir = useNavigate()

    const sifreDegistir = async (data) => {
        try {
            console.log("sifreDegistir data: ", data)
            setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:ChangingThePasswordFS"),
                beklesin: true})
            if(data.yenisifre !== data.teyitSifresi) {
                toast.remove("sifreDegistir1")
                toast.error(t("flashMessages:PasswordsDoNotMatchFS"), {id: "sifreDegistir1"})
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:ChangingThePasswordFS"),
                    beklesin: false})
                return 
            }
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            const yanit = await fetch(`/sifredegistir`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + kullanici.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            let veri = await yanit.json()
            if (!yanit.ok) {
                if(yanit.status === 401){
                    if(veri?.message === "Sifre Yanlis"){
                        toast.remove("sifreDegistir2")
                        toast.error(t("flashMessages:UserOrPasswordIsWrongFS"), {id: "sifreDegistir2"})
                    }
                    else if(veri?.message === "Sifreler Birbirini Tutmuyor"){
                        toast.remove("sifreDegistir2")
                        toast.error(t("flashMessages:PasswordsDoNotMatchFS"), {id: "sifreDegistir2"})
                    }
                    else {
                        toast.remove("sifreDegistir21")
                        toast.error(t("flashMessages:notAuthorizedToDoTheActionFS"), {id: "sifreDegistir21"})
                    }
                }
                else if(yanit.status === 409){
                    toast.remove("sifreDegistir3")
                    toast.error(t("flashMessages:thisEmailInUseFS"), {id: "sifreDegistir3"})
                    yonlendir(`/signin/${data.email}`)
                }
                else if(yanit.status === 500){
                    toast.remove("sifreDegistir4")
                    toast.error(t("flashMessages:server500ErrorFS"), {id: "sifreDegistir4"})
                }
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:KayitOluyorFS"),
                    beklesin: false})
            }
            else { //(!yanit.ok) {
                console.log("kayıtkullan yanit: ", yanit)
                const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
                if (veri?.sonuc === "Sifre Degistirme Basarili"){
                    let localStorageKullanici = JSON.parse(localStorage.getItem('kullanici'))
                    localStorageKullanici.isaret = isaret
                    localStorage.setItem("kullanici", JSON.stringify(localStorageKullanici)) //bunu yapmazsan bazen kaydetmiyor
                    dispatch({type: 'PROFILE_UPDATED', payload: localStorageKullanici})
                    console.log("Şifre Değiştirdeki Mantık Doğru Mu?")
                    setBeklemekte({beklemeMesaji: "",
                        beklesin: false})
                    toast
                    .success(t("flashMessages:SuccessfullyChangedThePasswordFS"))
                    setYukluyor(false)
                    setBeklemekte({beklemeMesaji: t(""),
                        beklesin: false})
                    }
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:KayitOluyorFS"),
                beklesin: false})
        }
    }

    return { sifreDegistir, yukluyor }
}