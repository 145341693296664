import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useSifreSifirlamaTalepEt = () => {
    const { t } = useTranslation();
    const [ yukluyor, setYukluyor] = useState(false)
    // const { dispatch } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte } = useGenelAyarlarBaglami()
    const yonlendir = useNavigate()

    const sifreSifirlamaTaleptEt = async (data) => {
        try {
            setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:SifreSifirlamaTalebiGonderiliyorFS"),
                beklesin: true})
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            // console.log("document.cookie güvenli mi?", document.cookie)
            const yanit = await fetch(`/sifresifirlamatalepet`, {
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            const veri = await yanit.json()
            if (!yanit.ok) {
                console.log("yanit: ", yanit)
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:SifreSifirlamaTalebiGonderiliyorFS"),
                    beklesin: false})
                if(yanit.status === 401){
                    if(veri?.sonuc === "Kayda Yonlendir"){
                        // return <Navigate to={`/signup/${veri.email}`}/>
                        toast.remove("sifreSifirlamaTalepEtKullan1")
                        toast.error(t("flashMessages:UserCannotBeFoundFS"), {id: "sifreSifirlamaTalepEtKullan1"})
                        yonlendir(`/signup/${data.email}`)
                    }
                }
                else if(yanit.status === 500){
                    toast.remove("sifreSifirlamaTalepEtKullan2")
                    toast.error(t("flashMessages:server500ErrorFS"), {id: "sifreSifirlamaTalepEtKullan2"})
                }
            }
            // const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
            // let veri = await yanit.json()
            if (veri?.sonuc === "Sifre Degistirebilirsiniz"){
                localStorage.removeItem('kullanici')
                // dispatch({type: 'SIGNOUT', payload: null})
                toast
                .success(t("flashMessages:YourPassWordMustBeResetFS"))
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:SifreSifirlamaTalebiGonderiliyorFS"),
                    beklesin: false})
                yonlendir(`/sifresifirla/${data.email}`)
                // setYonlendirilecekURL((y) => y = `/sifresifirla/${data.email}`)
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:SifreSifirlamaTalebiGonderiliyorFS"),
                beklesin: false})
        }
    }

    return { sifreSifirlamaTaleptEt, yukluyor }
}