import { useDispatch } from "react-redux";
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { v4 as uuidV4} from 'uuid'
import Peer from 'peerjs';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useIstemciSoketiKullan } from "./istemciSoketiKullan";


let peerLocal = null
// let sunucudanGelenOdaListesi = []

export const usePeerJSKullan = () => {
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const {
        odayaKatil,
        odadanAyril,
    } = useIstemciSoketiKullan()

    const [localPeerId, setLocalPeerId ] = useState()
    const [localPeer, setLocalPeer ] = useState()
    const [ sunucudanGelenOdaListesi, setSunucudanGelenOdaListesi ] = useState([])
    const [ sunucudanGelenVideoOdasindakiKullanicilar, setSunucudanGelenVideoOdasindakiKullanicilar ] = useState([])
    const [ sunucudanGelenSoket, setSunucudanGelenSoket ] = useState()
    const dispatchforVideoDilimi = useDispatch()
    const { t } = useTranslation()

    const peerJSSunucusunaBaglan = async () => {
        try {
            const yeniYerelKimlik = uuidV4()
            // const peerLocal = new Peer(yeniYerelKimlik)
            const peerLocal = new Peer(yeniYerelKimlik, {
                host: window.location.hostname,
                port: 3000,
                token: kullanici?.isaret,
                path: '/peerjs'
            })
            peerLocal.on('open', (sunucudanGelenPeerIdsi) => {
                setLocalPeerId(sunucudanGelenPeerIdsi)
                setLocalPeer(peerLocal)
                console.log("useEffect peerLocal.on('open': peerLocal ", peerLocal)
                console.log("useEffect peerLocal.on('open': sunucudanGelenPeerIdsi ", sunucudanGelenPeerIdsi)
                dispatch({type: 'PEER_JS_PEER_ID_CONNECTED', payload: sunucudanGelenPeerIdsi})
                odayaKatil( 
                    {
                    kullanicininSocketIOSoketi: kullanici?.kullanicininSocketIOSoketi,
                    sunucudanGelenPeerIdsi
                    }
                )
            })
            peerLocal.on('close', () => {
                console.log("peerLocal.on('close'")
            })
            peerLocal.on('disconnected', () => {
                console.log("peerLocal.on('disconnected'")
                dispatch({type: 'PEER_JS_PEER_ID_DISCONNECTED', payload: null})
                odadanAyril(localPeerId)
            })
            peerLocal.on('error', (err) => {
                console.log("peerLocal.on('error': ", err)
            })
        } catch (err) {
            console.log("peerJSSunucusunaBaglan err: ", err)
        }
    }

    return { 
        peerJSSunucusunaBaglan,
        localPeer,
        localPeerId,
    }
}