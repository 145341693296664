import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'

const Korunmayan = () => {
    const { kullanici } = useKimlikDogrulamaBaglami()
    return (
        <>
        {/* {console.log(kullanici)} */}
            {kullanici ? <Navigate to={'/'}/> : <Outlet/> }
        </>
    )
}

export default Korunmayan