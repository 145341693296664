import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Webcam from 'react-webcam'

import './VideoVeChatSayfasi.css'
import { useIstemciSoketiKullan } from '../kancalar/istemciSoketiKullan';
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import SohbetKutusu from '../Bilesenler/SohbetKutusu'
import { usePeerJSKullan } from '../kancalar/peerJsKullan';

const VideoVeChatSayfasi = () => {
    const { uuid } = useParams()
    const { 
            kullanici, 
            dispatch,
        } = useKimlikDogrulamaBaglami()
    const videoConstraints = {
        width: 640,
        height: 360,
        facingMode: "user"
    }
    const {peerJSSunucusunaBaglan} = usePeerJSKullan()
    
        useEffect(() => {
            try {
                peerJSSunucusunaBaglan()
            } catch (err) {
                console.log("useEffect err: ", err)
            }
        }, [])

    return (
        <div className='videoOdasiAnaKapsayicisiCls'>
        <div className='videoKapsayicisiCls'>
            {/* {JSON.stringify(localPeer)} */}
            {/* <Webcam 
            audio={true}
            videoConstraints={videoConstraints}
            /> */}
        </div>
        <SohbetKutusu />
        </div>
    )
}

export default VideoVeChatSayfasi