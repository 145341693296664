import React, { createContext, useEffect, useReducer } from "react";
// import { useDispatch } from "react-redux";

export const KimlikDogrulamaBaglami = createContext({});

// const yerelDepodan = localStorage.getItem("kullanici")
// console.log("yerelDepodan: ", yerelDepodan)

const initialState = {
    kullanici: null,
    kullaniciyaAitVideoOdalari: [],
    kullanicininPeerJSIDsi: null,
}

// console.log("KimlikDogrulamaBaglami initialState: ", JSON.stringify(initialState))

export const kimlikReducer = (state, action) => {
    console.log("action.type: ", action.type)
    let tmpKullanici = JSON.parse(localStorage.getItem("kullanici"))
    switch (action.type) {
        case "SOKET_OLUSTUR":
            console.log("action.payload: ", action.payload)
            if(tmpKullanici){
                tmpKullanici.kullanicininSocketIOSoketi = action.payload
                localStorage.setItem('kullanici', JSON.stringify(tmpKullanici))
                return {
                    ...state,
                    kullanici: tmpKullanici,
                }
            }
            return state
        case "SOKETI_YOK_ET":
            // tmpKullanici = localStorage.getItem("kullanici")
            if(tmpKullanici){
                tmpKullanici.kullanicininSocketIOSoketi = null
                localStorage.setItem('kullanici', JSON.stringify(tmpKullanici))
                return {
                    ...state,
                    kullanici: tmpKullanici,
                }
            }
            return state
        case "SIGNUP":
            localStorage.setItem('kullanici', JSON.stringify(action.payload))
            return {
                ...state,
                kullanici: action.payload,
            }
        case "SIGNIN":
            localStorage.setItem('kullanici', JSON.stringify(action.payload))
            return {
                ...state,
                kullanici: action.payload,
            }
        case "EMAIL_VERIFIED":
            return {
                ...state,
                kullanici: action.payload,
            }
        case "PROFILE_UPDATED":
            localStorage.setItem('kullanici', JSON.stringify(action.payload))
            return {
                ...state,
                kullanici: action.payload,
            }
        case "REDIRECT":
            return {
                ...state,
                kullanici: action.payload,
            }
        case "SIGNOUT":
            localStorage.removeItem('kullanici')
            return {
                ...state,
                kullanici: null,
                kullaniciyaAitVideoOdalari: [],
            }
        case "VIDEO_ROOMS_CREATED_BY_THE_USER":
            if(tmpKullanici){
                tmpKullanici.kullaniciyaAitVideoOdalari = action.payload
                localStorage.setItem('kullanici', JSON.stringify(tmpKullanici))
                return {
                    ...state,
                    kullanici: tmpKullanici,
                    kullaniciyaAitVideoOdalari: action.payload,
                }
            }
            return state
        case "PEER_JS_PEER_ID_CONNECTED":
            // console.log("PEER_JS_PEER_ID_CONNECTED if dışına geliyor mu?")
            if(tmpKullanici){
                // console.log("PEER_JS_PEER_ID_CONNECTED geliyor mu?")
                tmpKullanici.kullanicininPeerJSIDsi = action.payload
                localStorage.setItem('kullanici', JSON.stringify(tmpKullanici))
                return {
                    ...state,
                    kullanici: tmpKullanici,
                    kullanicininPeerJSIDsi: action.payload,
                }
            }
            return state
        case "PEER_JS_PEER_ID_DISCONNECTED":
            // console.log("PEER_JS_PEER_ID_CONNECTED if dışına geliyor mu?")
            if(tmpKullanici){
                // console.log("PEER_JS_PEER_ID_CONNECTED geliyor mu?")
                tmpKullanici.kullanicininPeerJSIDsi = null
                localStorage.setItem('kullanici', JSON.stringify(tmpKullanici))
                return {
                    ...state,
                    kullanici: tmpKullanici,
                    kullanicininPeerJSIDsi: null,
                }
            }
            return state
        default:
            return state
    }
}

export const KimlikDogrulamaSaglayici = ({ children }) => {
    
    const [
        state,
        dispatch] = useReducer(kimlikReducer, initialState)

    // console.log("KimlikDogrulamaSaglayici içi state : ", JSON.stringify(state))
    // console.log(dispatch)

    useEffect(() => {
        // state = {...state, JSON.parse(localStorage.getItem("kullanici"))}
        state.kullanici = JSON.parse(localStorage.getItem("kullanici"))
        // console.log("kimlik bağlamı useeffect state:", JSON.stringify(state))
        // const { kullanici } = state
        if(state) {
            dispatch({type: "SIGNIN", payload: state.kullanici}) //zaten giriş yapmış anlamında
        }
        // console.log("kullanici bağlamı useeffect kontrolden sonra state kullanici: ", JSON.stringify(state.kullanici))
    }, [])

    return (
        <KimlikDogrulamaBaglami.Provider value={
                {
                    ...state,
                    dispatch
                }
            }>
            { children }
            {/* {console.log("state: ", state)} */}
        </KimlikDogrulamaBaglami.Provider>
    )
}

export default KimlikDogrulamaBaglami; //bunu kullanmak zorunda mısın? Değilsen çıkart bunu