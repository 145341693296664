import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useEpostaDogrulama = () => {
    const { t } = useTranslation();
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte } = useGenelAyarlarBaglami()
    const yonlendir = useNavigate()

    const epostaDogrula = async (data) => {
        try {
            setYukluyor(true)
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            // console.log("document.cookie güvenli mi?", document.cookie)
            setBeklemekte({beklemeMesaji: t("flashMessages:YourEmailBeingVerifiedFS"),
                beklesin: true})
            const yanit = await fetch(`/emaildogrulama`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + kullanici.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            if (!yanit.ok) {
                // console.log("yanit: ", yanit)
                if(yanit.status === 401){
                    toast.remove("epostaDogrulama1")
                    toast.error(t("flashMessages:YourEmailVerificatiobCodeExpiredFS"), {id: "epostaDogrulama1"})
                }
                else if(yanit.status === 403){
                    toast.remove("epostaDogrulama2")
                    toast.error(t("flashMessages:YourEmailCannotBeVerifiedFS"), {id: "epostaDogrulama2"})
                }
                else if(yanit.status === 500){
                    toast.remove("epostaDogrulama3")
                    toast.error(t("flashMessages:server500ErrorFS"), {id: "epostaDogrulama3"})
                }
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:YourEmailBeingVerifiedFS"),
                    beklesin: false})
            }
            // const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
            let veri = await yanit.json()
            if (veri?.sonuc === "Eposta Dogrulama Basarili"){
                localStorage.removeItem('kullanici')
                // çıkış yap
                dispatch({type: 'SIGNOUT', payload: null})
                toast
                .success(t("flashMessages:YourEmailVerifiedFS"))
                toast
                .success(t("flashMessages:PleaseLogInFS"))
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:YourEmailBeingVerifiedFS"),
                    beklesin: false})
                yonlendir("/")
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:YourEmailBeingVerifiedFS"),
                beklesin: false})
                yonlendir("/")
        }
    }

    return { epostaDogrula, yukluyor }
}