import React from 'react'
import { Link } from 'react-router-dom'

const BaglantiliDugme = ({classes, to, children, clickEvent}) => {
    return (
        <Link to={to}><button 
        type='button' className={classes} 
        onClick={clickEvent}>
        {children}
        </button></Link>
    )
}

export default BaglantiliDugme