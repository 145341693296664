import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";
import { useIsaretTazele } from "./isaretTazeleKullan";

export const useProfilGetir = () => {
    const { t } = useTranslation();
    const [ yukluyor, setYukluyor] = useState(false)
    const [ getirilenProfil, setGetirilenProfil] = useState()
    const [ profiliGosterme, setProfiliGosterme] = useState(false)
    const { dispatch } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, 
        setBeklemekte, 
        genelAyarlarGuncelemeTarihi } = useGenelAyarlarBaglami()
    const {isaretTazele} = useIsaretTazele()
    const yonlendir = useNavigate()

    const profilGetir = async (data) => {
        try {
            setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                beklesin: true})
            const isarettmp = data.isaret
            if(typeof(isarettmp) === "undefined"){
                console.log("profilGetir buraya geliyor mu")
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                    beklesin: false})
                    yonlendir("/")
                return
            }
            const yanit = await fetch(`/hesabim`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + data.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: ""
            })
            if(yanit.status === 500){
                toast.remove("profilGetirKullan2")
                toast.error(t("flashMessages:server500ErrorFS"), {id: "profilGetirKullan2"})
                setProfiliGosterme(true)
                /* localStorage.removeItem('kullanici')
                dispatch({type: 'SIGNOUT', payload: null}) */
            }
            const cekilenVeri = await yanit.json()
            if (!yanit.ok) {
                if(yanit.status === 401){
                    if(cekilenVeri?.sonuc === "Yassah Ki Yassah") {
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else if ((cekilenVeri?.sonuc === "Caylari Tazele") ){
                        isaretTazele()
                    }
                    else if ((cekilenVeri?.sonuc === "Tekrar Giris Yap") ){
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else if ((cekilenVeri?.sonuc === "Sifre Sifirla") ){
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else {
                        toast.remove("profilGetirKullan1")
                        toast.error(t("flashMessages:notAuthorizedToDoTheActionFS", {id: "profilGetirKullan1"}))
                    }
                }
                setProfiliGosterme(true)
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                    beklesin: false})
            }
            else { //(!yanit.ok) {
                setGetirilenProfil(cekilenVeri.profilVerileri)
                if(cekilenVeri.profilVerileri.toBeRedirectedTo !== ''){
                    console.log("profilGetir if(veri.toBeRedirectedTo !== ''){")
                    if (genelAyarlarGuncelemeTarihi === 0) {
                        console.log("profilGetir if (guncellemeTarihi === 0) {")
                        toast.remove("profilGetirKullan3")
                        toast.error(t("flashMessages:YourProfileIsIncompleteFS"), {id: "profilGetirKullan3"})
                        toast.remove("profilGetirKullan4")
                        toast.success(t("flashMessages:YouMustAtLeastChangeUsernameFS"), {id: "profilGetirKullan4"})
                    } // if (genelAyarlarGuncelemeTarihi === 0) {
                } // if(cekilenVeri.profilVerileri.toBeRedirectedTo !== ''){
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                    beklesin: false})
                setYukluyor(false)
                setProfiliGosterme(false)
            } //else { //(!yanit.ok) {
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                beklesin: false})
            setProfiliGosterme(true)
        }
    }

    return { profilGetir, yukluyor, getirilenProfil, profiliGosterme }
}